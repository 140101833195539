/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/Layout';
import SEO from '../components/SEO';
import ParagraphBlockElement from '../components/layout/ParagraphBlockElement';
import { ScrollContainer } from '../context/scrollBehavior';
import HeroLandingPage from '../components/layout/hero/HeroLandingPage';
import useAuth from '../util/hooks/useAuth';
import useGetLandingPage from '../util/hooks/useGetLandingPage';

const ContemporaryLandingPage = ({ data, location }) => {
  useAuth();
  const [device, setDevice] = useState('mobile');
  const { setLandingPage, landingPageData } = useGetLandingPage();
  const artist = location.state?.artist;
  const landingPage = data.DrupalParagraphs.edges[0].node;
  const { title, field_json_api_call_data, drupal_id } = landingPage;

  useEffect(() => {
    setLandingPage(drupal_id);
  }, [drupal_id, setLandingPage]);

  const homepage_hero = data.hero_images.nodes.filter(
    (image) => image.relationships.field_artist_ref?.title === artist
  );
  const cont_hero = data.hero_images.nodes.filter(
    (image) =>
      image.relationships.field_artist_ref?.relationships.field_artist_type
        .name === 'Established living contemporary'
  );
  const cont_random = Math.floor(
    Math.random() * (cont_hero.length - 1 - 0 + 1) + 0
  );
  const cont_image = cont_hero[cont_random];

  // get crop for contemporary
  const cont_crop = data.hero_image_crop.edges.find(({node: crop}) => {
      // compare images
      const cont_image = cont_hero[cont_random]?.relationships?.field_landing_page_banner?.uri?.url;
      if (cont_image) {
          let crop_image = crop?.uri;
          if (crop_image) {
             crop_image = crop_image.replace("public://", "/sites/default/files/");
             if (crop_image === cont_image) {
              return true;
             }
          }
      }
      return false;
  });

  const subMenu = { title: 'contemporary', extra: true };

  return (
    <>
      <SEO title="Contemporary" />
      <Layout
        device={device}
        setDevice={setDevice}
        logo={data.logo}
        menu={data.allMenuMenuLink}
        subMenu={subMenu}
        footerMenu={data.footer_menu}
        locations={data.locations.edges}
        className="landing-contemporary"
      >
        <ScrollContainer force={true}>
          {cont_image && (
            <HeroLandingPage
              data={homepage_hero.length > 0 ? homepage_hero[0] : cont_image}
              crop={cont_crop}
              device={device}
              height="800px"
            />
          )}
        </ScrollContainer>
        <ParagraphBlockElement
          blocks={field_json_api_call_data}
          device={device}
          title={title}
          drupal_id={drupal_id}
          page_data={landingPageData}
        />
      </Layout>
    </>
  );
};

export default ContemporaryLandingPage;

export const ContemporaryLandingPageQuery = graphql`
  query ContemporaryLandingPageQuery {
    DrupalParagraphs: allNodeLandingPage(
      filter: { title: { eq: "Contemporary" } }
    ) {
      edges {
        node {
          drupal_id
          title
          field_json_api_call_data {
            type
            block_title
            url
            block {
              body {
                value
              }
              use_frame_animation
            }
            view_more_link {
              title
              uri
            }
            additional_info {
              content_type
              fields_machine_names
              no_items_per_row
            }
            items {
              itemNode {
                title
                field_art_year
                field_artwork_name
                field_embedded_code
                relationships {
                  field_v_artist_ref {
                    relationships {
                      node__exhibition {
                        field_dates {
                          value
                          end_value
                        }
                        path {
                          alias
                        }
                        title
                        relationships {
                          field_locations_ref {
                            title
                          }
                        }
                      }
                    }
                  }
                  field_artist_listing_image {
                    uri {
                      url
                    }
                    image_style_uri {
                      gatsby_artwork_image
                      gatsby_artwork_thumbnail
                      gatsby_landing_page_banner
                      gatsby_artist_pic
                    }
                  }
                  field_artist_photo {
                    uri {
                      url
                    }
                    image_style_uri {
                      gatsby_artwork_image
                      gatsby_artwork_thumbnail
                      gatsby_landing_page_banner
                      gatsby_artist_pic
                    }
                  }
                  field_artist_ref {
                    field_firstname
                    field_lastname
                    drupal_id
                    title
                    relationships {
                      field_artist_photo {
                        uri {
                          url
                        }
                        image_style_uri {
                          gatsby_artwork_image
                          gatsby_artwork_thumbnail
                          gatsby_landing_page_banner
                          gatsby_artist_pic
                        }
                      }
                      field_artist_listing_image {
                        uri {
                          url
                        }
                        image_style_uri {
                          gatsby_artwork_image
                          gatsby_artwork_thumbnail
                          gatsby_landing_page_banner
                          gatsby_artist_pic
                        }
                      }
                    }
                    path {
                      alias
                    }
                  }
                  field_artist_type {
                    name
                  }
                  field_artwork_images {
                    uri {
                      url
                    }
                    image_style_uri {
                      gatsby_artwork_image
                      gatsby_artwork_thumbnail
                      gatsby_landing_page_banner
                      gatsby_artist_pic
                    }
                  }
                  field_artwork_medium {
                    name
                  }
                  field_artwork_status {
                    name
                  }
                  field_artwork_type {
                    name
                  }
                  field_locations_ref {
                    title
                  }
                  field_featured_image {
                    uri {
                      url
                    }
                    image_style_uri {
                      featured_image_crop
                      gatsby_artwork_image
                      gatsby_artwork_thumbnail
                      gatsby_landing_page_banner
                      gatsby_artist_pic
                    }
                  }
                }
                field_firstname
                field_lastname
                field_artist_payment
                field_art_price_original {
                  currency_code
                  number
                }
                field_circa
                field_depth
                field_exceptional_artwork_sold
                field_featured_contemporary
                field_featured_historic
                field_featured_link_text
                field_featured_order
                field_order_exceptional_sale
                field_price_avail_on_request
                field_promoted_artwork
                field_publish_to_rss_feed
                field_published_sold_artwork
                field_sort_order
                field_width
                field_height
                field_year_born
                path {
                  alias
                }
                body {
                  processed
                }
                field_dates {
                  end_value
                  value
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
  hero_image_crop: allCropLandingPageBannerCrop {
    edges {
      node {
        height
        uri
        width
        x
        y
        entity_id
      }
    }
  }
    hero_images: allNodeArtwork(
      filter: { field_feature_on_homepage_hero: { eq: true } }
    ) {
      nodes {
        field_artwork_name
        path {
          alias
        }
        relationships {
          field_landing_page_banner {
            uri {
              url
            }
            image_style_uri {
              featured_image_crop
              gatsby_artwork_image
              gatsby_artwork_thumbnail
              gatsby_landing_page_banner
              gatsby_artist_pic
              artwork_langin
            }
          }
          field_artwork_images {
            uri {
              url
            }
            image_style_uri {
              featured_image_crop
              gatsby_artwork_image
              gatsby_artwork_thumbnail
              gatsby_landing_page_banner
              gatsby_artist_pic
            }
          }
          field_banner {
            uri {
              url
            }
            image_style_uri {
              gatsby_landing_page_banner
              banner_manual_crop
              artwork_langin
            }
          }
          field_landing_page_banner {
            uri {
              url
            }
            image_style_uri {
              gatsby_landing_page_banner
              banner_manual_crop
              artwork_langin
            }
          }
          field_artist_ref {
            title
            path {
              alias
            }
            relationships {
              field_artist_type {
                name
              }
            }
          }
        }
      }
    }
  }
`;
