import React from 'react';
import { Link } from 'gatsby';
import ImageFromField from '../../global/ImageFromField';
import GlobalLink from '../../global/Link';
import { useWindowResize } from '../../..//util/hooks/useWindowResize';
import ImageInView from '../../fx/animate/ImageInView';

const HeroLandingPage = ({ device, height, data, crop = null }) => {
  const { title } = data?.relationships?.field_artist_ref;

  const [image, setImage] = React.useState(null);
  const [t, setTitle] = React.useState(null);
  const [d, setData] = React.useState(null);
  const [cropData, setCropData] = React.useState(null)

  React.useEffect(() => {
    if (typeof window === null) return;
    if (crop === null) {
      const banner = data?.relationships?.field_landing_page_banner?.image_style_uri.find((i) => i.artwork_langin);
      let img = data.relationships.field_artwork_images[0];
      if ( banner?.artwork_langin) {
        img = {
          uri: { url: banner?.artwork_langin.replace("http://18.219.219.206:8080", "") }
        }
      }
      setImage(img)
      setTitle(title)
      setData(data);
    } else {
      let banner_image = crop?.node?.uri;
      if (banner_image) {
        banner_image = banner_image.replace("public://", "/sites/default/files/");
        const img = {
          uri: { url: `https://cms.lochgallery.com${banner_image}` }
        }
        setCropData({...crop.node});
        setTitle(title);
        setData(data);
        setImage(img);
      }
    }
  }, [])

  return (
    <div className="lt-hero-oneup-container" style={{ maxHeight: height }}>
      <div className="hero_image">
        {cropData ? (
          <ImageAsCropped
            img={image}
            crop={cropData}
            className="d-crop"
            style={{ maxHeight: height, width: '100%'}}
          />
        ) : (
          <ImageFromField
            img={image}
            fallback={null}
            style={{ maxHeight: height, width: '100%', objectFit: 'cover' }}
            fromUrl={true}
          />
        )}
      </div>
      <div className="wrapper">
        <div className="hero_content">
          <div className="grid-content-container">
            <div className="t-content-container t-center landing-page-content">
              <p>
                Collecting art is a journey, it will enrich your life and
                enhance your investment.
              </p>
            </div>
            <GlobalLink
              button
              className={`btn-outline btn-light btn-large`}
              path={`/artworks/type/${d?.relationships?.field_artist_ref?.relationships?.field_artist_type?.name}`}
              linkkey={`hl_historic`}
              device={device}
            >
              View Available Artworks
            </GlobalLink>
          </div>
        </div>
        {t && (
          <Link to={d?.path?.alias} className="hero-artist">
            {t}
          </Link>
        )}
      </div>
    </div>
  );
};

export default HeroLandingPage;

const ImageAsCropped = ({img, crop, ...rest}) => {
  const r = {...rest}
  const style = {...rest.style};
  const [naturalDimensions, setNaturalDimensions] = React.useState(null);
  const windowSize = useWindowResize();

  // get natural intrinsic image size
  React.useEffect(() => {
    const psuedoImage = new Image();
    psuedoImage.onload = function() {
      setNaturalDimensions({
        height: this.naturalHeight,
        width: this.naturalWidth
      })
    }
    psuedoImage.src = img?.uri?.url;
  }, [])

  if (!naturalDimensions) return <ImageInView src={img?.uri?.url} />;

  // get crop size percentage based on width
  const aspect = windowSize.width / naturalDimensions.width;
  const cropHeightAR = crop.height > 800 ? crop.height / 800 : 800 / crop.height;
  const cropWidth = crop.width * aspect;
  const cropHeight = 800;

  const width = naturalDimensions.width * aspect * cropHeightAR;
  const height = naturalDimensions.height * aspect * cropHeightAR;

  let cropOffsetX = (crop.x - (crop.width/2)) * aspect * cropHeightAR;
  const cropOffsetY =  (crop.y - (crop.height/2)) * aspect * cropHeightAR;

  if (Math.abs(cropOffsetX) < 10) cropOffsetX = 0;
  
  return (
    <div className="cropped-image-wrapper"
      style={{
        height: `${cropHeight}px`,
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        border: '0'
      }}
    >
      <ImageInView
      src={img?.uri?.url}
      style={{
        height: height,
        width: width,
        marginLeft: `-${cropOffsetX}px`,
        marginTop: `-${cropOffsetY}px`,
        position: 'absolute',
      }}
      />
    </div>
  )
}